import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Hero from '../components/Hero';
import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
      }
    }
  }
`;

const Layout = ({ children }) => {

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"/>
            <meta property="og:title" content={siteMetadata.title} />
            {
              // <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/th-academy/css/salesforce-lightning-design-system.min.css"/>
              // iframeListener()
            }
          </Helmet>
          <div id="cusp-site" className="cusp-site">
            {children}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
